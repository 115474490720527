<template>
  <div ref="chart" class="chart"></div>
</template>

<script>
import * as charts from 'echarts'

export default {
  name: "System_data",
  data() {
    return {
      system_info: [
        // {'cpu': 10, 'memory': 54, 'time': '2022-8-31 18:59'},
        // {'cpu': 11, 'memory': 45, 'time': '2022-8-31 19:00'},
        // {'cpu': 12, 'memory': 50, 'time': '2022-8-31 19:01'},
        // {'cpu': 9, 'memory': 55, 'time': '2022-8-31 19:02'},
        // {'cpu': 13, 'memory': 60, 'time': '2022-8-31 19:03'},
      ],
      chart: null
    }
  },
  methods: {
    chart_ini() {
      this.chart = charts.init(this.$refs.chart)
      this.chart.showLoading()
      this.chart.setOption({
        title: {
          text: '系统状态'
        },
        tooltip: {},
        dataset: {
          source: this.system_info
        },
        xAxis: {
          type: 'category',
          name: '时间',
          axisLabel: {
            formatter: function (value) {
              return value.split(' ')[1]
            }
          }
        },
        yAxis: {
          min: 0,
          max: 100,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        legend: {},
        series: [{
          name: 'cpu占用',
          type: 'line',
          symbol:'none',
          smooth: true,
          encode: {
            x: 'time',
            y: 'cpu'
          },
          areaStyle: {}
        }, {
          name: '内存占用',
          type: 'line',
          symbol:'none',
          smooth: true,
          encode: {
            x: 'time',
            y: 'memory'
          },
          areaStyle: {}
        }]
      })
      this.load_system_info()
    },
    load_system_info() {
      this.$axios.get('/api/system').then(res => {
        this.system_info = res.data
        this.chart.setOption({
          dataset: {
            source: this.system_info
          },
        })
        this.chart.hideLoading()
      })
    }
  },
  mounted() {
    this.chart_ini()
    // this.chart_ini()
    setInterval(() => {
      this.load_system_info()
    }, 1000*60)
  }
}
</script>

<style scoped>
.chart {
  width: 600px;
  height: 400px;
}
</style>